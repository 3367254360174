export default [
  {
    image: '/img/1.png',
    sound: '/sound/3.mp3'
  },
  {
    image: '/img/2.png',
    sound: '/sound/6.mp3'
  },
  {
    image: '/img/3.png',
    sound: '/sound/1.mp3'
  },
  {
    image: '/img/4.png',
    sound: '/sound/9.mp3'
  },
  {
    image: '/img/5.png',
    sound: '/sound/11.mp3'
  },
  {
    image: '/img/6.png',
    sound: '/sound/2.mp3'
  },
  {
    image: '/img/7.png',
    sound: '/sound/8.mp3'
  },
  {
    image: '/img/8.png',
    sound: '/sound/10.mp3'
  },
  {
    image: '/img/9.png',
    sound: '/sound/7.mp3'
  },
  {
    image: '/img/10.png',
    sound: '/sound/5.mp3'
  },
  {
    image: '/img/11.png',
    sound: '/sound/4.mp3'
  },
  {
    image: '/img/12.png',
    sound: '/sound/12.mp3'
  }
];
